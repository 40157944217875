.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.input-field {
    outline: 0.5px solid black;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}



.submit-button {
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    border-color: #00A6A6;
    border-style: solid;
    color: #00A6A6;
    font-weight: 700;
    border-radius: 15px;
    font-size: 18px;
    margin: 0 auto;
    width: 100%;
    display: block;

}

button:hover {
    background-color: #00A6A6;
    font-weight: 700;
    border: 1px solid #ccc;
    color: white;

}

/* Modal CSS */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* Box shadow for depth */
    z-index: 1000;
    /* Ensure modal is above other content */
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}