h2 {
    font-size: 28px;
}

form {
    margin-top: 25px;
}

.profilePage {
    height: 100%;
}

.columns {
    margin: 50px;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.leftColumn {
    flex: 1; 
}

.rightColumn {
    flex: 2; 
}

.columnSeparator {
    width: 2px;
    background-color: black;
    margin-right: 25px;
}

.name {
    display: flex;
    flex-direction: column;
}

.gender-age {
    display: flex;
    flex-direction: column;
}

.age {
    margin-left: 0;
}

.lastName {
    margin-left: 0;
}

.settingItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-right: 35px;
    border-top: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
}

.settingItems {
    padding-top: 25px;
}

.submitButton {
    background-color: #00A6A6;
    border-color: #00A6A6;
    border-style: solid;
    color: white;
    height: 50px;
    width: 160px;
    border-radius: 8px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (min-width: 640px) {
    .age {
        margin-left: 50px; 
    }

    .gender-age {
        flex-direction: row; 
    }

    .lastName {
        margin-left: 50px;
    }

    .name {
        flex-direction: row;
    }

    .columns {
        flex-direction: row;
    }
}