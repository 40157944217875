.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
}

.cardbutton {
  background-color: #00A6A6;
  border-color: #00A6A6;
  border-style: solid;
  color: white;
  border: 2px solid #00A6A6;
  font-weight: 700;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardbutton:hover {
  background-color: white;
  border: 2px solid #00A6A6;
  color: #00A6A6;
}

.cardbuttonGrid {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.events-container {
  text-align: center;
  padding: 20px;
}

.attendingevents-container {
  margin-top: 50px;
}

.myevents-container {
  margin-top: 50px;
}

h2 {
  margin-bottom: 15px;
}


.event-card {
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(0,0,0,0.25) 0 0 5px;
  min-height: 220px;
  min-width: 290px;
  padding: 25px;
  text-align: left;
  
}

.event-card h2 {
  margin-top: 0;
}

.event-card button {
  margin-right: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  border: 1px solid black;
  background-color: lightgray;
  cursor: pointer;
}

.buttons {
  margin-top: 20px;
}


.show-managed:hover,
.create-event:hover {

  background-color: white;
  border-color: #00A6A6;
  border-style: solid;
  color: #00A6A6;
  font-weight: 700;
  width: 45%;
  height: 80%;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  flex: 1;
  min-width: 100px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-event,
.show-managed {
  background-color: #00A6A6;
  border: none;
  color: white;
  font-weight: 700;
  width: 45%;
  height: 80%;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  flex: 1;
  min-width: 100px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 50px;
  max-width: 1900px;
  padding: 40px;
  justify-items: center;
}

h1 {
  font-weight: bold;
  margin: 20px;
  text-align: center;
  font-size: 30px
}

h3 {
  font-weight: bold;
  font-size: 1.2em;
}