.input-label {
    margin-bottom: 5px;
    display: block;
}

.input-field {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 15px;
}

.input-component {
    margin-bottom: 15px;
}

.required {
    border-color: red;
}