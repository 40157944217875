.create-button {
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #00A6A6;
    border-color: #00A6A6;
    border-style: solid;
    color: white;
    font-weight: 700;
    border-radius: 15px;
    font-size: 18px;
    margin: 0 auto;
    width: 100%;
    display: block;

}

.create-button:hover {
    background-color: white;
    font-weight: 700;
    border: 1px solid #00A6A6;
    color: #00A6A6;
    width: 100%;


}